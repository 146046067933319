import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox.jsx';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Self-signed SSL certificate for the INSTAR MQTT Broker and Home Assistant",
  "path": "/Frequently_Asked_Question/INSTAR_MQTT_Home_Assistant_Self-Signed_Certificate/",
  "dateChanged": "2020-03-01",
  "author": "Mike Polinowski",
  "excerpt": "How do I use a custom SSL certificate for the INSTAR MQTT Broker with Home Assistant?",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "Home Assistant"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='FAQs - Self-signed SSL certificate for the INSTAR MQTT Broker and Home Assistant' dateChanged='2020-03-01' author='Mike Polinowski' tag='INSTAR IP Camera' description='How do I use a custom SSL certificate for the INSTAR MQTT Broker with Home Assistant?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_MQTT_Home_Assistant_Self-Signed_Certificate/' locationFR='/fr/Frequently_Asked_Question/INSTAR_MQTT_Home_Assistant_Self-Signed_Certificate/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "self-signed-ssl-certificate-for-the-instar-mqtt-broker-and-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#self-signed-ssl-certificate-for-the-instar-mqtt-broker-and-home-assistant",
        "aria-label": "self signed ssl certificate for the instar mqtt broker and home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Self-signed SSL certificate for the INSTAR MQTT Broker and Home Assistant`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How do I use a custom SSL certificate for the INSTAR MQTT Broker with Home Assistant?`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: We already showed how to set up your `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/"
      }}>{`INSTAR MQTT Broker with a self-signed SSL cert`}</a>{`. Following this guide we now have one camera configured as our MQTT Broker and a second cameras configured to be a MQTT Client on our MQTT network. Both cameras communicate SSL encrypted using a self-signed certificate.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-setup"
        }}>{`Camera Setup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#home-assistant-setup"
        }}>{`Home Assistant Setup`}</a></li>
    </ul>
    <h2 {...{
      "id": "camera-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-setup",
        "aria-label": "camera setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Setup`}</h2>
    <p>{`The broker camera is using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/#brokerpem"
      }}>{`broker.pem`}</a>{` file as SSL certificate:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "914px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/260c792d508b548d9e8e7d663c991d2c/076ca/Home_Assistant_with_INSTAR_MQTT_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACaklEQVQoz12Sy08TURTGuyf4ToiABWNr6cwA8mo7hdICAwUszNw7r/ueO5C6ZCECkurKhYkuDEJVWvwHYMHWtX+ZaaeU4peTk7v55XzfOTeW0een57LHtY+/Gs2zev3k9PSs/qPRaPbW+Xmj2bw4+X66abqLup7R84Xickafj01OTo5PTCRePC9XP8NPfyq169e166X9q9Lby1bfvyztXy61+8bRlfz2d00cP3l4f/hZPJFIxjRNU1V1LPWyErwLahfkoE6PfqKDu3XY6uz9ufjwu+xWNXVMVRVNU2NqW4qirK0UQ+aH3A+Zv8N9SV2O7IA4AjutjuzA23IqK8CsOJ69bVZKxcWYoiiapqXT6XJ5nYuAMh4VtB0IbdApCGzX2fvq7H0xgWnC7Y3N9cLCfCydTkfw6uoqxtjzPNd1Pc+z/5fjVg+9N0frG+XSSrG4vJjLZTuwoiiGYWCMpZRBEEgpKaUAANgjsLXpgu2FQiE5lpzNzs3MzrTgKHMEU0ohhNGsLmbbNqWUUIYwyeYyw/HB8VcT0xEc2TYMgxDCOYcAAgCABQgiIhCMMSGElJJQghCay8w+evxg4OlAIpm8kxm1hTHGCPvct5Ftd0y0XPC2pqan+vr7+u/1Dw0Ndu6sKEoEM8YopZxxFCILWfAmtWma1WpVSpnL5UZGRkZHR1Op1C1sGAZjLAgCy7Ii2xHZelqWEIJz7nlePp+Px+O3cDczpTQMw95VAQAQQkKIMAyllAihfD5/Z3IXZozt7u46jtM9EgCAEBKG4U5bGGNd12/h6Ht2F8YYc12393NACC3L8n2fUhpN7tr+BwCQEbnBqsZHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/260c792d508b548d9e8e7d663c991d2c/e4706/Home_Assistant_with_INSTAR_MQTT_01.avif 230w", "/en/static/260c792d508b548d9e8e7d663c991d2c/d1af7/Home_Assistant_with_INSTAR_MQTT_01.avif 460w", "/en/static/260c792d508b548d9e8e7d663c991d2c/6ab89/Home_Assistant_with_INSTAR_MQTT_01.avif 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/260c792d508b548d9e8e7d663c991d2c/a0b58/Home_Assistant_with_INSTAR_MQTT_01.webp 230w", "/en/static/260c792d508b548d9e8e7d663c991d2c/bc10c/Home_Assistant_with_INSTAR_MQTT_01.webp 460w", "/en/static/260c792d508b548d9e8e7d663c991d2c/38f11/Home_Assistant_with_INSTAR_MQTT_01.webp 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/260c792d508b548d9e8e7d663c991d2c/81c8e/Home_Assistant_with_INSTAR_MQTT_01.png 230w", "/en/static/260c792d508b548d9e8e7d663c991d2c/08a84/Home_Assistant_with_INSTAR_MQTT_01.png 460w", "/en/static/260c792d508b548d9e8e7d663c991d2c/076ca/Home_Assistant_with_INSTAR_MQTT_01.png 914w"],
              "sizes": "(max-width: 914px) 100vw, 914px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/260c792d508b548d9e8e7d663c991d2c/076ca/Home_Assistant_with_INSTAR_MQTT_01.png",
              "alt": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "title": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And is configured to use it's own MQTT broker (127.0.0.1) with a user login:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/142fb/Home_Assistant_with_INSTAR_MQTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdElEQVQoz12Sy07bQBSG8wCglkUXSYDECWAHhyBRIFxjQ4jt8d0zHjO+ERsaRCsoKgpFrVRVoC6r0vQqUdF3qGDJtrs+VBUHpYVPv2b36fxHZ1KVSqU8NVWix3j/tfzicuPpF273c63VuZfVVoff/Wge/4SH57lcPp1O5/P5VE9mxovG8070/sY5/WWfXuGzK3x2/X/s02vv3XXrw419fDFB08VicXx8LDU5OVkul0ulkijUm4HnE4xMDRqqpSv3YmoAedsGcnRDUVSZ51dTpVKpJwNZjqI4DMMgCInrQoggRMi2MXaQbffS2t2zsWMh0zD19frarcwwjCRJQRAQQlzXJYQghCzLQghhjI076CIQBKmxsrp8K9M0LUlSFEVhGPq+DyG0LAtCCADgOK5arS4kLC8tCkJjemZ6fnF+rjr/r7YkSZ7nOY6DMUYIwQTTNHVdVxMURTZsTwDK8EiarZRnZh/fmRzHse/7fbMna5qWuN3X3IwEWctkHuWo0QmGvrNzHMeO41gJiYsAkOrraxxXq60sVedmVhZmea42+GBwYHBgaOhhimXZfm1CiJ3Qa44gBKqxJmrroqpu7hhR24zbDaBlMulsNpsdzqbKCd1TAdBsNre2toIgcF0XY6yb8OSJefkKXxwpOyed6NOf+Py3aIVUbqRQLBYKha7MsizDMKIoEkIwxrZtJ7Ut3ULtCHw7FL8e1P2dlrz31nj2RlAMKjdKUQWKom4n0zQtiqLjON1Fu1+j2922DG37xGxfgqMfLw33AkjfZQU3GiM9tS8zDMPzvKqqsiyDPpIoQ08LDmR/PwD6/oawLwCB43P5fOJSfwG5nvPg888w0AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/e4706/Home_Assistant_with_INSTAR_MQTT_02.avif 230w", "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/d1af7/Home_Assistant_with_INSTAR_MQTT_02.avif 460w", "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/81d9e/Home_Assistant_with_INSTAR_MQTT_02.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/a0b58/Home_Assistant_with_INSTAR_MQTT_02.webp 230w", "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/bc10c/Home_Assistant_with_INSTAR_MQTT_02.webp 460w", "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/468b0/Home_Assistant_with_INSTAR_MQTT_02.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/81c8e/Home_Assistant_with_INSTAR_MQTT_02.png 230w", "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/08a84/Home_Assistant_with_INSTAR_MQTT_02.png 460w", "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/142fb/Home_Assistant_with_INSTAR_MQTT_02.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f805ead8b6bd3e5b1a770ca0c9e56af/142fb/Home_Assistant_with_INSTAR_MQTT_02.png",
              "alt": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "title": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "home-assistant-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-assistant-setup",
        "aria-label": "home assistant setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Assistant Setup`}</h2>
    <p>{`Start by adding the MQTT Broker to the HA `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{`. But make sure that you only have one MQTT broker added to HA. If you already added your broker without SSL, following our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/#mqtt-binding"
      }}>{`installation steps`}</a>{`, you can go to Configuration/Integrations and delete this instance before continuing:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mqtt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`broker`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 192.168.2.117
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8883`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`certificate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ./mqtt/client.pem
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`client_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` homeassistant
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` admin
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tls_insecure`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span></code></pre></div>
    <p>{`This will add our broker camera with the IP `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{` via the SSL port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8883`}</code>{` and with the broker login we set inside the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`camera's webUI`}</a>{`. For the certificate I created a folder next to the HA `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{` file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt`}</code>{` and uploaded the public key `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`client.pem`}</code>{` that we `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/#clientpem"
      }}>{`created for our MQTT broker earlier`}</a>{`.`}</p>
    <p>{`At this stage HA was complaining that the certificate did not match the domain `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{` which is why I set the flag `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tls_insecure: true`}</code>{` which shouldn't be a security issue since we are our own authority. When using a CA certificate (not self-signed) this option should not be necessary.`}</p>
    <p>{`We can clean this configuration up by removing our secret login and adding it to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`secrets.yaml`}</code>{` file that should be in the same directory as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{`:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mqtt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`broker`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 192.168.2.117
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8883`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`certificate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ./mqtt/client.pem
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`client_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` homeassistant
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!secret`}</span>{` mqtt_username
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!secret`}</span>{` mqtt_password
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tls_insecure`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`secrets.yaml`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mqtt_username`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` admin
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`mqtt_password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar`}</code></pre></div>
    <p>{`Now we have to check if our configuration is valid by going to Configuration/Server Controls:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/257f374f6fa204e0f536482c9afd7b67/a2b88/Home_Assistant_with_INSTAR_MQTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABfElEQVQoz02SS27cMAyGfb0uu+klesM26AGybFfzCDIBko49M36JpB4kRRWykmk+GARh65P0E+6IaFmW6/Xa9/04jjlnVS2lqCoRWSnAtiTzUswMABxAzrlsdDFG7z0AhBBijK0y83svOgWZgsxRk2iKFRFRVWbumoaIbTPn3DAMl8vlfD5P0yzMwkmF61pm2UgpiQgAdM45ImLmJiPiuq7OuVpb41wIMSX+zLvcjl3XtclE1IKEzSCiEIOZlQ/uHSJ28zwTISK2MYQQhmHY7XaHw+H5dDrs9y8vJwAgRCL0FQKAlGr8LrKgD7dpBqyzJe+J0DnnAFoRkXagivB2+5pZVUS6P0s+gv1eyjPZm883R+V+ya25nfvX4/7v0xORv78spdRpJ+YkSjFJtmxFRFrCvK35cZYvP5dvv8avD+P3RySxu17lt3EFwMswxBiro5pzto1iRqy9l2vIl6A3L/rxycyqfJwjhbiuKwC0Hyt9QjiZcHtyjfyfEMI/kwF5XFpMqxEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/257f374f6fa204e0f536482c9afd7b67/e4706/Home_Assistant_with_INSTAR_MQTT_03.avif 230w", "/en/static/257f374f6fa204e0f536482c9afd7b67/d1af7/Home_Assistant_with_INSTAR_MQTT_03.avif 460w", "/en/static/257f374f6fa204e0f536482c9afd7b67/984a9/Home_Assistant_with_INSTAR_MQTT_03.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/257f374f6fa204e0f536482c9afd7b67/a0b58/Home_Assistant_with_INSTAR_MQTT_03.webp 230w", "/en/static/257f374f6fa204e0f536482c9afd7b67/bc10c/Home_Assistant_with_INSTAR_MQTT_03.webp 460w", "/en/static/257f374f6fa204e0f536482c9afd7b67/1f8a9/Home_Assistant_with_INSTAR_MQTT_03.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/257f374f6fa204e0f536482c9afd7b67/81c8e/Home_Assistant_with_INSTAR_MQTT_03.png 230w", "/en/static/257f374f6fa204e0f536482c9afd7b67/08a84/Home_Assistant_with_INSTAR_MQTT_03.png 460w", "/en/static/257f374f6fa204e0f536482c9afd7b67/a2b88/Home_Assistant_with_INSTAR_MQTT_03.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/257f374f6fa204e0f536482c9afd7b67/a2b88/Home_Assistant_with_INSTAR_MQTT_03.png",
              "alt": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "title": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And if it is all ok, we can hit Restart to load our configuration update:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ddd76eb354599615a343ca70b4398d4/142fb/Home_Assistant_with_INSTAR_MQTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABaUlEQVQoz32QQY4TMRBF+3a5RKQkN8h1gAsMi2QVWOQMIIHYsWBFpJ7p6XbbrrJd5SobnIYwAoUnL7yoX//X7xDRWjsMQ9/34ziKSM651ioiAaHUClzmVEKuquq9986rar3SpZQQ0TkXQogxUkqRBbi4pCZQkmK5zFQcF1HN3FDVUkrOuQOAEAIiLsvaj2RKOiclqf+BiDrnXJsn+iUOGFhNKjaVUurL9xfM3GIDgLX25hxITFITNUsT3aM5z8YAACIsNVydm9hR0evQvdhXZxaM8XkyDrB1i5iIkXIkXpqnO8QYuy9Gvlr5ZPSbl++QBwvOzrMxztrncZymSeVPb0vgJSMAdPpPHgB4fHoahuFyufT9IzbAex9DvK2otXrvu/fvTsfD4e3Dw+l0Op/Ph+Px88cPJUVGFCZRXcIzc875pbNzrtvv9+v1erfbbTab7Xa7Wq3evH5Va40hUErETET8m9vBzPzT+QfET3DzDPXSLwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ddd76eb354599615a343ca70b4398d4/e4706/Home_Assistant_with_INSTAR_MQTT_04.avif 230w", "/en/static/2ddd76eb354599615a343ca70b4398d4/d1af7/Home_Assistant_with_INSTAR_MQTT_04.avif 460w", "/en/static/2ddd76eb354599615a343ca70b4398d4/81d9e/Home_Assistant_with_INSTAR_MQTT_04.avif 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ddd76eb354599615a343ca70b4398d4/a0b58/Home_Assistant_with_INSTAR_MQTT_04.webp 230w", "/en/static/2ddd76eb354599615a343ca70b4398d4/bc10c/Home_Assistant_with_INSTAR_MQTT_04.webp 460w", "/en/static/2ddd76eb354599615a343ca70b4398d4/468b0/Home_Assistant_with_INSTAR_MQTT_04.webp 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ddd76eb354599615a343ca70b4398d4/81c8e/Home_Assistant_with_INSTAR_MQTT_04.png 230w", "/en/static/2ddd76eb354599615a343ca70b4398d4/08a84/Home_Assistant_with_INSTAR_MQTT_04.png 460w", "/en/static/2ddd76eb354599615a343ca70b4398d4/142fb/Home_Assistant_with_INSTAR_MQTT_04.png 907w"],
              "sizes": "(max-width: 907px) 100vw, 907px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ddd76eb354599615a343ca70b4398d4/142fb/Home_Assistant_with_INSTAR_MQTT_04.png",
              "alt": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "title": "Home AssistantSelf-Signed Cert for INSTAR MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To test if the connection is successful we can use the MQTT test provided under `}<strong parentName="p">{`Developer Tools`}</strong>{`. My camera has the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/#syntax"
      }}>{`MQTT ID`}</a>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10D1DC218F96`}</code>{`. So updating the following topic with the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` should activate the Alarm Area 1 of my camera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`instar/10D1DC218F96/alarm/area1/enable/raw`}</code></pre></div>
    <p><img parentName="p" {...{
        "src": "/en/9d9552c73a4e423a2cfce64bb6ff0b93/Home_Assistant_with_INSTAR_MQTT_05.gif",
        "alt": "Home AssistantSelf-Signed Cert for INSTAR MQTT"
      }}></img></p>
    <p>{`It works!`}</p>
    <p>{`Now you can continue with our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/#adding-a-ui-switch"
      }}>{`regular tutorial to add your first switches and automations`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      